import {
	FETCH_JOB_START,
	FETCH_JOB_METADATA_SUCCESS,
	FETCH_JOB_LINES_SUCCESS,
	FETCH_JOB_POLYGON_SUCCESS,
	FETCH_JOB_DATA_SUCCESS
} from './actionTypes';

const initialState = {
	zoom: 4,
	lat: 54.74,
	lon: -98.57,
	height: 700,
	width: '100%',
	asset: null,
	selected: {},
	pin: null,
	job: null,
	line: null,
	isLoading: true,
	bounds: null,
	lineGeom: null,
	coordinates: null,
	geojson: null
};

const mapsReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case FETCH_JOB_START:
			return { ...state, ...payload };
		case FETCH_JOB_METADATA_SUCCESS:
			return { ...state, ...payload };
		case FETCH_JOB_LINES_SUCCESS:
			return { ...state, ...payload };
		case FETCH_JOB_POLYGON_SUCCESS:
			return { ...state, ...payload };
		case FETCH_JOB_DATA_SUCCESS:
			return { ...state, ...payload };
		default:
			return state;
	}
};

export default mapsReducer;
