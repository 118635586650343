/* eslint-disable import/no-cycle */
import * as MapProcessingUtil from 'aegion_common_utilities/lib/MapProcessingUtil';
import LocationType from 'aegion_common_utilities/lib/ReadingsUtil/LocationType';
import NoGpsReadingWithIndexError from 'aegion_common_utilities/lib/ErrorClasses/NoGpsReadingWithIndexError';

import JobUtil from '../../../../utils/JobUtil/JobUtil';
import AutoCorrectionUtil from '../../../../utils/AutoCorrectionUtil';
import DatFileUtil from '../../../../utils/DatFiles/DatFileUtil';

import { INPROGRESS, COMPLETE, NEW, setGlobalData } from '../job';
import { selectGlobalDataAndS3Data } from '../../../selectors/job';

// @todo - this is bad
// this file job/util/fileEdits.js is a catch all for functionality that should:
// 1 - be abstracted from thunk file if function is a utility file
// 2 - functions not properly formated to be a thunk, or were originally intended to be a helper function denoted by leading _ char
// @todo - revisit this file job/util/fileEdits.js, and clean up any function containing dispatch, move to appropriate place.
import {
	incrementDatFileSavingCount,
	_datFileSavingCompleted
} from '../job.thunks.fileEdits';

import { displayDatSaveMessage } from '../../message-box';

export const _getNewDataFromChangedDatFileFields = (
	datFile,
	getState,
	changes,
	options // can send data/globalData
) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	let data;
	let globalData;
	if (options && options.data && options.globalData) {
		data = options.data;
		globalData = options.globalData;
	} else {
		data = job.data;
		globalData = job.globalData;
	}

	const newData = data.map(oldDatFile => {
		if (oldDatFile.fileName === datFile.fileName) {
			return {
				...oldDatFile,
				isEdited: true,
				...changes
			};
		}

		return oldDatFile;
	});

	const newGlobalData = {
		...globalData,
		MasterLST: {
			...globalData.MasterLST,
			[datFile.fileName]: {
				...globalData.MasterLST[datFile.fileName],
				...changes
			}
		}
	};

	return {
		data: newData,
		globalData: newGlobalData
	};
};

export const _getNewDataFromChangedDatFileField = (
	datFile,
	getState,
	fieldName,
	fieldValue,
	options
) => {
	return _getNewDataFromChangedDatFileFields(
		datFile,
		getState,
		{ [fieldName]: fieldValue },
		options
	);
};

const _getGpsReadingsFromUpdatingSingleReading = (
	oldGpsReadings,
	gpsReadingIndex,
	readingUpdate = {}
) => {
	return oldGpsReadings.map((oldGpsReading, n) => {
		if (n !== gpsReadingIndex) {
			return oldGpsReading;
		}

		return {
			...oldGpsReading,
			...readingUpdate
		};
	});
};

export const _getDataFromUpdatingSingleReading = (
	getState,
	datIndex,
	gpsReadingIndex,
	readingUpdate = {}
) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { data, globalData } = job;
	let gpsreadings;
	let datFileName;
	let datFileKey;

	const newData = data.map((oldDatFile, i) => {
		if (i === datIndex) {
			datFileName = oldDatFile.fileName;
			datFileKey = oldDatFile.fileKey;
			gpsreadings = _getGpsReadingsFromUpdatingSingleReading(
				oldDatFile.gpsreadings,
				gpsReadingIndex,
				readingUpdate
			);
			return {
				...oldDatFile,
				isEdited: true,
				gpsreadings
			};
		}

		return oldDatFile;
	});

	const newGlobalData = {
		...globalData,
		MasterLST: {
			...globalData.MasterLST,
			[datFileName]: {
				...globalData.MasterLST[datFileName],
				isEdited: true,
				gpsreadings
			}
		}
	};
	return {
		data: newData,
		globalData: newGlobalData,
		datFileName,
		datFileKey
	};
};

// @todo - this should be a selector
export const _dataHasGpsReadings = data => {
	return data.every(datFile => datFile.gpsreadings);
};

// @todo - this should be a selector
export const _getDatFileByIndex = (getState, datIndex) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { data } = job;

	return data[datIndex];
};

const _getAzimuth = (gpsreading1, gpsreading2) => {
	if (gpsreading1.nextAzimuth !== undefined) {
		return gpsreading1.nextAzimuth;
	}

	return MapProcessingUtil.calculateAzimuthFromGpsReadings(
		gpsreading1,
		gpsreading2
	);
};

const _getDistance = (gpsreading1, gpsreading2) => {
	if (gpsreading1.nextAzimuth !== undefined) {
		return gpsreading1.nextDistance;
	}

	return MapProcessingUtil.calculateDistance(gpsreading1, gpsreading2, {
		to: 'feet'
	});
};

const _getCoordsForSkip = (
	datFile,
	gpsreadings,
	readingIndex,
	numberOfSkips
) => {
	const reading = gpsreadings[readingIndex];

	let nextGpsReadingIndex;
	let nextGpsReading;

	// First we try to get the "nextGpsReading" so we can calculated interpolated points
	try {
		const { i, gpsReading } = MapProcessingUtil.getNextGpsReading(
			gpsreadings,
			readingIndex
		);
		nextGpsReadingIndex = i;
		nextGpsReading = gpsReading;
	} catch (e) {
		// If there is not a "nextGpsReading", just reading the current coordinates...perhaps this should not happen in actuality, but it is possible
		if (e instanceof NoGpsReadingWithIndexError) {
			return reading.coordinates;
		}

		throw e;
	}

	// Determing which reading should be the "starting" (gps) reading - we may have to look backwards to find a gps reading
	let gpsReadingIndex;
	let gpsReading;
	if (MapProcessingUtil.isGps(reading)) {
		gpsReadingIndex = readingIndex;
		gpsReading = reading;
	} else {
		const {
			i: foundGpsReadingIndex,
			gpsReading: foundGpsReading
		} = MapProcessingUtil.getPreviousGpsReading(
			datFile.gpsreadings,
			readingIndex
		);

		gpsReadingIndex = foundGpsReadingIndex;
		gpsReading = foundGpsReading;
	}

	const gapCount = nextGpsReadingIndex - gpsReadingIndex;

	const totalGapCount = gapCount + numberOfSkips;

	const nextAzimuth = _getAzimuth(gpsReading, nextGpsReading);
	const nextDistance = _getDistance(gpsReading, nextGpsReading);

	return MapProcessingUtil.calculateInterpolatedPosition(
		gpsReading.coordinates[1],
		gpsReading.coordinates[0],
		nextDistance,
		nextAzimuth,
		1,
		totalGapCount
	);
};

export const _getNewGpsReadingsFromAddingSkip = (
	datFile,
	readingIndex,
	numberOfSkips
) => {
	// TODO: We should probably take "reverse" into account
	const { interval, gpsreadings } = datFile;
	let readingFoundAndAdded = false;
	const newGpsReadings = [];

	gpsreadings.forEach((reading, i) => {
		const { StationId } = reading;

		const newStationId = readingFoundAndAdded
			? StationId + numberOfSkips * interval
			: StationId;
		newGpsReadings.push({
			...reading,
			StationId: newStationId
		});

		if (readingIndex !== i) {
			return;
		}

		readingFoundAndAdded = true;
		let stationId = reading.StationId;

		const coords = _getCoordsForSkip(
			datFile,
			gpsreadings,
			readingIndex,
			numberOfSkips
		);

		for (let j = 0; j < numberOfSkips; j += 1) {
			stationId += interval;

			newGpsReadings.push({
				code: 4,
				locationType: LocationType.INTERPOLATED,
				coordinates: coords,
				StationId: stationId
			});
		}
	});

	MapProcessingUtil.updateGPSMeasurements(newGpsReadings, {
		isUpdateInterpolatedCoords: true
	});

	return newGpsReadings;
};

export const addStationToTrims = (trims, value) => {
	return trims.map(trim => {
		const { start, end } = trim;
		const startNumber = start.number + value;
		const endNumber = end.number + value;
		return {
			...trim,
			start: {
				...start,
				number: startNumber
			},
			end: {
				...end,
				number: endNumber
			}
		};
	});
};

// @deprecated
// @todo - replace and remove
export const _getCurrentData = getState => {
	const state = getState();
	const { globalData, data } = selectGlobalDataAndS3Data(state);
	return { globalData, data };
};

export const _saveJobFromStationAlignment = async (
	update,
	datFile,
	dispatch
) => {
	return new Promise((res, rej) => {
		JobUtil.update(update, error => {
			if (error) {
				rej(error);
				dispatch(displayDatSaveMessage(error, null, datFile));
				return;
			}
			res();
		});
	});
};

const _resetStationing = (datFiles, startStn = 0) => {
	let startingStation = startStn;

	return datFiles.map(datFile => {
		const { readingsCount, interval } = datFile;

		const endingStation = startingStation + (readingsCount - 1) * interval;
		const newDatFile = {
			...datFile,
			startStn: startingStation,
			endStn: endingStation
		};

		startingStation = endingStation;

		return newDatFile;
	});
};

export const _sortDatFilesByStartStn = (datFiles, ascending = true) => {
	return datFiles.slice().sort((a, b) => {
		if (ascending) {
			return a.startStn - b.startStn;
		}

		return b.startStn - a.startStn;
	});
};

export const _resortGlobalData = (globalData, sortedDatFiles) => {
	const newMasterLST = {};

	sortedDatFiles.forEach(datFile => {
		newMasterLST[datFile.fileName] = datFile;
	});

	const newGlobalData = {
		...globalData,
		MasterLST: newMasterLST
	};

	return newGlobalData;
};

export const _saveJobFromStationAligments = async (
	globalData,
	datFiles,
	dispatch
) => {
	const { timeuuid } = globalData;

	for (let i = 0; i < datFiles.length; i += 1) {
		const datFile = datFiles[i];
		const { fileName, startStn, endStn } = datFile;

		const update = {
			startStn,
			endStn,
			dat: fileName,
			job: timeuuid
		};
		// eslint-disable-next-line no-await-in-loop
		await _saveJobFromStationAlignment(update, datFile, dispatch);
	}
};

const _getSmallestStartStn = datFiles => {
	const startStns = datFiles.map(d => d.startStn);

	return Math.min(...startStns);
};

export const _getNewStationsForFlips = (datFiles, startStn) => {
	// First reverse the datFiles (highest station is first)
	const sortedByStnDescending = _sortDatFilesByStartStn(datFiles, false);

	// Then we recalculate startStn, endStn - from 0 -> end of all stations
	return _resetStationing(sortedByStnDescending, startStn);
};

export const _getNewStartStationForReverse = (startStnFromUser, datFiles) => {
	const isStartStnFromUserNumerical = startStnFromUser > -Infinity;
	if (isStartStnFromUserNumerical) {
		return startStnFromUser;
	}

	return _getSmallestStartStn(datFiles) || 0;
};

const _promptUserForStartStn = datFile => {
	const { startStn } = datFile;

	// Keep trying until we get a good value
	// eslint-disable-next-line no-constant-condition
	while (true) {
		const promptMessage = `This dat file currently starts with station ID ${startStn}. Please provide a new start station.`;
		// eslint-disable-next-line no-alert
		const startStnText = window.prompt(promptMessage, startStn);
		const newStartStn = parseFloat(startStnText);

		// eslint-disable-next-line no-restricted-globals
		if (!isNaN(newStartStn)) {
			return newStartStn;
		}

		// Send them a message and try again
		// eslint-disable-next-line no-alert
		window.alert(
			`"${newStartStn}" does not appear to be a number. Please provide a numerical value`
		);
	}
};

export const _getStartStationFromUser = datFile => {
	const { startStn } = datFile;
	if (startStn === 0) {
		return 0;
	}

	const confirmationMessage =
		'By default, flipping the line will recalculate the starting station id to 0. Would you like to provide another value? (note that selecting "cancel" will reset the starting station to 0, selecting "ok" will allow you to provide another value).';
	// eslint-disable-next-line no-alert
	if (!window.confirm(confirmationMessage)) {
		return 0;
	}

	return _promptUserForStartStn(datFile);
};

// TODO: This needs to work for trims as well
// TODO: Should send segment event
export const _getNewGpsReadingsFromChangingInterval = (datFile, interval) => {
	const newGpsReadings = [];
	const { startStn } = datFile;
	let nextStationId = startStn;

	datFile.gpsreadings.forEach(gpsreading => {
		const newReading = {
			...gpsreading,
			StationId: nextStationId
		};
		nextStationId += interval;

		newGpsReadings.push(newReading);
	});

	return newGpsReadings;
};

// Will only pick "visible" files
export const _updateJobWithSingleDatFileChange = (
	field,
	value,
	jobUtilFunction,
	options = {}
) => (dispatch, getState) => {
	// If eventName is available, a segment event will be called at the end
	const { eventName } = options;

	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { globalData, data } = job;
	const jobId = globalData.timeuuid;

	const files = DatFileUtil.getVisibleFiles(globalData).map(({ name }) => {
		return {
			name,
			[field]: value
		};
	});

	if (files.length === 0) {
		return Promise.resolve();
	}

	let newGlobalData = globalData;
	let newData = data;

	const changesByFile = [];
	const datFilesWithChanges = [];

	files.forEach(({ name }) => {
		const datFile = globalData.MasterLST[name];
		const changes = _getNewDataFromChangedDatFileField(
			datFile,
			getState,
			field,
			value,
			{ data: newData, globalData: newGlobalData }
		);

		newGlobalData = changes.globalData;
		newData = changes.data;

		changesByFile.push({
			oldValue: datFile[field],
			newValue: value
		});
		datFilesWithChanges.push(datFile);
	});

	// No changes have been made, nothing to do
	if (newGlobalData === globalData) {
		return Promise.resolve();
	}

	dispatch(setGlobalData(newGlobalData, newData));
	const fileNames = files.map(f => f.name);
	incrementDatFileSavingCount()(dispatch);

	const promise = new Promise((res, rej) => {
		jobUtilFunction({ job: jobId, files }, err => {
			if (err) {
				dispatch(setGlobalData(globalData, data));
				dispatch(_datFileSavingCompleted(err, null, { fileNames }));

				return rej(err);
			}

			dispatch(_datFileSavingCompleted(null, null, { fileNames }));
			return res();
		});
	});

	return promise.then(() => {
		if (!eventName) {
			return;
		}

		AutoCorrectionUtil.analyticsTrackMisc(
			eventName,
			{ globalData: newGlobalData, datFiles: datFilesWithChanges },
			{
				changesByFile
			}
		);
	});
};

export const _sendSegmentEventForJobStatusChange = (
	oldGlobalData,
	newStatus,
	datFiles,
	setByAllFileStatusComplete = false
) => {
  if (oldGlobalData === null || oldGlobalData === undefined) {
    console.error(new Error('_sendSegmentEventForJobStatusChange() --> param oldGlobalData is not defined'));
  }
  const { jobStatus: oldStatus = 'MISSING' } = oldGlobalData || {};
  

	AutoCorrectionUtil.analyticsTrackMisc(
		'job-status-change',
		{ globalData: oldGlobalData, datFiles },
		{
			newStatus,
			oldStatus,
			setByAllFileStatusComplete
		}
	);
};

export const _callUpdateJobStatus = (
	jobId,
	status,
	{ setByAllFileStatusComplete = false } = {}
) => (dispatch, getState) => {
	const { globalData: oldGlobalData, data: oldData } = _getCurrentData(
		getState
	);
	return new Promise((res, rej) => {
		JobUtil.changeStatus(jobId, status, err => {
			if (err) {
				rej(err);
				dispatch(setGlobalData(oldGlobalData, oldData));
			} else {
				res();
				_sendSegmentEventForJobStatusChange(
					oldGlobalData,
					status,
					oldData,
					setByAllFileStatusComplete
				);
			}
		});
	});
};

const changeSurveyStatusToInProgress = (
	globalData,
	value,
	dispatch,
	newGlobalData,
	getState,
	data
) => {
	if (globalData.jobStatus !== NEW) {
		return;
	}

	if (
		globalData.jobStatus === NEW &&
		(value === INPROGRESS || value === COMPLETE)
	) {
		_callUpdateJobStatus(globalData.timeuuid, INPROGRESS)(
			dispatch,
			getState
		).then(() => {
			const newGlobalDataModified = {
				...newGlobalData,
				jobStatus: INPROGRESS
			};
			dispatch(setGlobalData(newGlobalDataModified, data));
		});
	}
};

export const _additionalStatusChanges = fileStatus => (dispatch, getState) => {
	const state = getState();
	const { cisview } = state;
	const { job } = cisview;
	const { globalData, data } = job;
	const jobId = globalData.timeuuid;
	const newGlobalData = globalData;
	const isAllComplete = Object.keys(newGlobalData.MasterLST).every(fileName => {
		if (
			newGlobalData.MasterLST[fileName] !== 'length' &&
			newGlobalData.MasterLST[fileName].fileStatus === COMPLETE
		) {
			return true;
		}
		return false;
	});
	if (!isAllComplete) {
		changeSurveyStatusToInProgress(
			globalData,
			fileStatus,
			dispatch,
			newGlobalData,
			getState,
			data
		);

		return;
	}

	if (
		// eslint-disable-next-line no-alert
		!window.confirm(
			`All files for "${newGlobalData.surveyType}" survey have been completed, do you wish to change the survey status to Complete as well?`
		)
	) {
		return;
	}
	_callUpdateJobStatus(jobId, COMPLETE, {
		setByAllFileStatusComplete: true
	})(dispatch, getState).then(() => {
		const newGlobalDataModified = {
			...newGlobalData,
			jobStatus: COMPLETE
		};
		dispatch(setGlobalData(newGlobalDataModified, data));
	});
};
