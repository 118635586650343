/* eslint-disable import/no-cycle */
import {
	SET_VIEWPORT,
	SET_ASSET,
	SET_PIN,
	SET_CLUSTER,
	SET_MAP,
	SET_IS_GRID_ENABLED,
	SET_EXTENT,
	SET_GROUP_LAYER_UPDATING,
	SET_ASSET_LAYER_UPDATING,
	SET_HEATMAP_LAYER_UPDATING
} from './actionTypes';

import assetViewActions from '../../actions/actions';

const initialState = {
	zoom: 4,
	lat: 54.74,
	lon: -98.57,
	height: 400,
	width: '100%',
	cluster: false,
	map: undefined,
	dynamicLayerVisibility: {
		grids: false
	},
	extent: undefined,
	groupLayerUpdating: false,
	assetLayerUpdating: false,
	heatmapLayerUpdating: false
};

const mapsReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case assetViewActions.WINDOW_RESIZE:
			return { ...state, height: action.height - 105 };
		case SET_VIEWPORT:
			return {
				...state,
				zoom: payload.zoom,
				lat: payload.center[0],
				lon: payload.center[1]
			};
		case SET_ASSET:
			return { ...state, asset: payload };
		case SET_PIN:
			return { ...state, pin: [...payload.center] };
		case SET_CLUSTER:
			return { ...state, cluster: payload };
		case SET_IS_GRID_ENABLED:
			return {
				...state,
				dynamicLayerVisibility: {
					...state.dynamicLayerVisibility,
					grids: payload
				}
			};
		case SET_EXTENT:
			return { ...state, extent: payload };
		case SET_MAP:
			return { ...state, map: payload };
		case SET_GROUP_LAYER_UPDATING:
			return { ...state, groupLayerUpdating: payload };
		case SET_ASSET_LAYER_UPDATING:
			return { ...state, assetLayerUpdating: payload };
		case SET_HEATMAP_LAYER_UPDATING:
			return { ...state, heatmapLayerUpdating: payload };
		default:
			return state;
	}
};
export default mapsReducer;
