export const KEY_SUITE = 'SUITE';
export const KEY_SCANLINE = 'SCANLINE';
export const KEY_FIELDLINE = 'FIELDLINE';
export const KEY_ASSETVIEW = 'ASSETVIEW';
export const KEY_LIVELINE = 'LIVELINE';
export const KEY_CHARGELINE = 'CHARGELINE';
export const DEFAULT_KEY = KEY_SUITE;

const SUITE = {
	key: KEY_SUITE,
	tile: undefined,
	details: {
		title: 'Total Asset Awareness',
		details:
			"Protect the integrity of your assets with CorrproCanada's asset integrity apps. These focused web applications help you stay on top of asset status, make stronger decisions, plan more efficiently and stay responsive."
	}
};

const SCANLINE = {
	key: KEY_SCANLINE,
	tile: {
		name: 'SCANLINE®',
		imageSrc: 'Content/Images/dot-net-images/icon_scanline.png',
		hero: 'CIS Survey Analytics',
		copy: 'See the big picture right down the line.'
	},
	details: {
		title: 'Rapid & Accurate Analyses',
		details:
			'For corrosion specialists who need to quickly analyze corrosion survey readings and create action plans, SCANLINE® provides an advanced analytical framework for examining corrosion survey data.  Unlike other systems that are unable to load or view multiple datasets, SCANLINE® supports the simultaneous analyses of multiple corrosion survey types - significantly enhancing the results of corrosion data inspection.'
	}
};

const FIELDLINE = {
	key: KEY_FIELDLINE,
	tile: {
		name: 'FIELDLINE®',
		imageSrc: 'Content/Images/dot-net-images/icon_fieldline.png',
		hero: 'Survey Repository',
		copy: 'Access your CIS surveys and reports - in the cloud.'
	},
	details: {
		title: 'Repository of CIS Surveys & Reports',
		details:
			'FIELDLINE® provides a repository of current and historical CIS survey data, making it available in multiple formats.  The  easy-to-use GIS interface displays exceptions and problem areas using clear and simple symbology. One-click downloads provides data, reports and graphs - delivered directly to your desktop.'
	}
};

const ASSETVIEW = {
	key: KEY_ASSETVIEW,
	tile: {
		name: 'ASSETVIEW®',
		imageSrc: 'Content/Images/dot-net-images/icon_assetview.png',
		hero: 'Asset Management',
		copy: 'Manage CP test station surveys and assets.'
	},
	details: {
		title: 'Manage Assets & See Historical Trends',
		details:
			'ASSETVIEW® makes collecting and managing survey data easy & fast.  Advanced GIS functionality provides robust visualizations giving technicians and managers a clear view of non-compliant and deficient assets.  Using Correlator™, our proprietary data collection system, survey crews can sync field data with one click of a button, making data immediately available for analyses & reporting.'
	}
};

const LIVELINE = {
	key: KEY_LIVELINE,
	tile: {
		name: 'LIVELINE®',
		imageSrc: 'Content/Images/dot-net-images/icon_liveline.png',
		hero: 'Real-time Health',
		copy: 'Keep in touch with mission-critical assets.'
	},
	details: {
		title: 'Remote Monitoring & Diagnostics',
		details:
			'LIVELINE® gives you up-to-date measurements of your key assets - all from your office. This web-based app delivers data analytics of current and historical readings of CP test stations. This makes gathering data simpler, more efficient, and safer - while freeing up field technicians for more important asset integrity tasks.'
	}
};

const CHARGELINE = {
	key: KEY_CHARGELINE,
	tile: {
		name: 'CHARGELINE™',
		imageSrc: 'Content/Images/dot-net-images/icon_chargeline.png',
		hero: 'AC Interference',
		copy: 'Assess the potential for AC interference.'
	},
	details: {
		title: 'Assessment of AC Interference Threat',
		details:
			'CHARGELINE™ uses proprietary algorithms, advanced spatial analysis, and up-to-date powerline data to help assess the potential of AC interference of your pipeline. Just upload a pipeline segment and the geospatial analytics automatically identify potential AC interference problem areas. The dynamically generated report provides a complete picture of threat priorities and next step recommendations.'
	}
};

export const applications = [
	SCANLINE,
	FIELDLINE,
	ASSETVIEW,
	LIVELINE,
	CHARGELINE
];

export const detailsMap = [SUITE, ...applications].reduce((acc, obj) => {
	return {
		...acc,
		[obj.key]: obj.details
	};
}, {});

export default {
	applications,
	detailsMap
};
