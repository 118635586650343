/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
	KEY_SUITE,
	KEY_SCANLINE,
	KEY_FIELDLINE,
	KEY_ASSETVIEW,
	KEY_LIVELINE,
	KEY_CHARGELINE,
	DEFAULT_KEY
} from '../../content';

import './RequestMoreInfoButton.scss';

const mailToMeta = {
	[KEY_SUITE]: {
		email: 'aip@corrprocanada.com',
		subject: 'Request: More Info about AIM',
		body: [
			'Hello,',
			"I'd like to receive more information about your Pipeline & Asset Integrity Management software.",
			'Thank you,'
		].join('\n\n')
	},
	[KEY_SCANLINE]: {
		email: 'aip@corrprocanada.com',
		subject: 'Request: More Info about SCANLINE',
		body: [
			'Hello,',
			"I'd like to receive more information about your SCANLINE application.",
			'Thank you,'
		].join('\n\n')
	},
	[KEY_FIELDLINE]: {
		email: 'aip@corrprocanada.com',
		subject: 'Request: More Info about FIELDLINE',
		body: [
			'Hello,',
			"I'd like to receive more information about your FIELDLINE application.",
			'Thank you,'
		].join('\n\n')
	},
	[KEY_ASSETVIEW]: {
		email: 'aip@corrprocanada.com',
		subject: 'Request: More Info about ASSETVIEW',
		body: [
			'Hello,',
			"I'd like to receive more information about your ASSETVIEW application.",
			'Thank you,'
		].join('\n\n')
	},
	[KEY_LIVELINE]: {
		email: 'aip@corrprocanada.com',
		subject: 'Request: More Info about LIVELINE',
		body: [
			'Hello,',
			"I'd like to receive more information about your LIVELINE application.",
			'Thank you,'
		].join('\n\n')
	},
	[KEY_CHARGELINE]: {
		email: 'aip@corrprocanada.com',
		subject: 'Request: More Info about CHARGELINE',
		body: [
			'Hello,',
			"I'd like to receive more information about your CHARGELINE application.",
			'Thank you,'
		].join('\n\n')
	}
};

const selectMailToMeta = appKey =>
	mailToMeta[appKey] || mailToMeta[DEFAULT_KEY];

const createMailToString = appKey => {
	const mailTo = selectMailToMeta(appKey);
	const to = `mailto:${mailTo.email.trim()}`;
	const subject = `subject=${encodeURIComponent(mailTo.subject.trim())}`;
	const body = `body=${encodeURIComponent(mailTo.body.trim())}`;
	return `${to}?${subject}&${body}`;
};

const RequestMoreInfoButton = ({ appKey }) => {
	return (
		<a className="request-more-info-button" href={createMailToString(appKey)}>
			More Info
		</a>
	);
};

export default RequestMoreInfoButton;
