import { getCustomer } from '../../../../util/user';
import {
	getFilters,
	KEY_COMPANY,
	KEY_TEXT_SEARCH
} from '../../../util/filters';

const startingFilters = getFilters();
const sessionCustomer = getCustomer();
const canChangeCustomer = sessionCustomer === 'CorrproCanada';

export const initialState = {
	searchText: startingFilters[KEY_TEXT_SEARCH] || undefined,
	companyName: canChangeCustomer
		? startingFilters[KEY_COMPANY] || sessionCustomer
		: sessionCustomer
};

export default initialState;
