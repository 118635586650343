// aip.corrprocanada.com
const URL = 'https://api.aip.corrprocanada.com';

const TAB_PREFIX = '';
const TAB_SUFFIX = 'AIP';

const aipurls = {
	admin: 'https://api.aip.corrprocanada.com/admin',
	account: 'https://api.aip.corrprocanada.com/account',
	assetview: 'https://api.aip.corrprocanada.com/assetview',
	assetviewWS: 'wss://ws.aip.corrprocanada.com/assetview',
	assetviewCorrelator: 'https://api.aip.corrprocanada.com/assetview-correlator',
	customerusers: 'https://api.aip.corrprocanada.com/customer-users',
	cisview: 'https://api.aip.corrprocanada.com/cisview',
	cisviewextended: 'https://api.aip.corrprocanada.com/cisview-extended',
	cisviewreadonly: 'https://api.aip.corrprocanada.com/cisview-readonly',
	cisviewedits: `https://api.aip.corrprocanada.com/cisview-edits`,
	dashboard: 'https://api.aip.corrprocanada.com/dashboard',
	fieldline: 'https://api.aip.corrprocanada.com/fieldline',
	fieldlinebinary: 'https://api.aip.corrprocanada.com/fieldline-binary',
	chargeline: 'https://api.aip.corrprocanada.com/chargeline',
	chargelinebinary: 'https://api.aip.corrprocanada.com/chargeline-binary',
	maps: 'https://api.aip.corrprocanada.com/maps',
	scanline: 'https://api.aip.corrprocanada.com/scanline',
	scanlineWorkflow: 'https://api.aip.corrprocanada.com/scanline-workflow',
	scanlineReportV2: 'https://api.aip.corrprocanada.com/scanline-report-v2',
	scanlineReportV2WS: 'wss://ws.aip.corrprocanada.com/scanline-report-v2',
	scanlineTags: 'https://api.aip.corrprocanada.com/scanline-tags',
	templateViewer:
		'https://api.aip.corrprocanada.com/internal-template-viewer-2',
	templates: 'https://api.aip.corrprocanada.com/templates',
	scanlineReport: 'https://api.aip.corrprocanada.com/scanlineReport',
	liveline: 'https://api.aip.corrprocanada.com/liveline',
	settings: 'https://api.aip.corrprocanada.com/settings',
	uploaderbucket: 'corrprocanada-aip-prod',
	uploderbucketbasepath: 'https://api.aip.corrprocanada.com/uploader',
	uploaderwebsocket: 'wss://ws.aip.corrprocanada.com/uploader',
	uploaderbucketaccessurl: 'https://api.aip.corrprocanada.com/uploader/access',
	uploaderfilesubmiturl:
		'https://api.aip.corrprocanada.com/cisview-extended/postUploaderSubmit',
	parseFile: 'https://api.aip.corrprocanada.com/parse-file',
	parseFileWS: 'wss://ws.aip.corrprocanada.com/parse-file',
	mlServerApi: 'https://ml.aip.corrprocanada.com/',
	mlServerLambdaApi: 'https://api.aip.corrprocanada.com/ml/',
	downloadReport: 'https://api.aip.corrprocanada.com/dataprocessor-report',
	notifications: 'https://api.aip.corrprocanada.com/aip-notifications',
	viewAs: 'https://api.aip.corrprocanada.com/view-as',
	poolid: 'cognito-idp.us-west-2.amazonaws.com/us-west-2_8wbzgfn2H',
	identifypoolid: 'us-west-2:7bb4fa34-ff99-450f-ad1e-80ef94e1eea4',
	clientid: '4v5s182eqtcs252cg05ug6emu8',
	bucket: 'corrprocanada-aip-prod-nonversioned',
	versionedBucket: 'corrprocanada-aip-prod',
	dataIntegrity: 'https://api.aip.corrprocanada.com/dataIntegrity'
};

export default aipurls;

export { URL, TAB_PREFIX, TAB_SUFFIX };
