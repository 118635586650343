/* eslint-disable no-unused-vars */
import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';

import { MiscUtils } from 'aegion_common_utilities/';

const segmentKeyOther = 'UNDEFINED';
const segmentKeyProd = 'UNDEFINED';

// function getSegmentKey() {
// 	if (MiscUtils.isProd()) {
// 		return segmentKeyProd;
// 	}
// 	return segmentKeyOther;
// }

// const analytics = Analytics({
// 	app: 'UNDEFINED',
// 	plugins: [
// 		segmentPlugin({
// 			writeKey: getSegmentKey()
// 		})
// 	]
// });

export const page = () => {
  return undefined;
	// analytics.page();
};

export const track = (name, payload) => {
  return undefined;
	// analytics.track(name, payload);
};

export const identify = (uuid, payload) => {
  return undefined;
	// analytics.identify(uuid, payload);
};
