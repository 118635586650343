export const initialState = {
	items: [],
	flatItems: [],
	totalCount: undefined,
	isLoadingItems: false,
	isLoadedItems: false,
	loadItemsErrorMessage: undefined
};

export const FLAT_KEYS = {
	line: 'Line',
	jobNumber: 'Job Number',
	jobStartDate: 'Job Start Date',
	jobEndDate: 'Job End Date',
	jobUpdatedDate: 'Job Updated Date',
	customer: 'Customer',
	entity: 'Entity',
	projectManager: 'Project Manager',
	corrosionTechnician: 'Corrosion Technician',
	aegionPm: 'CorrproCanada PM',
	aegionDps: 'CorrproCanada DPs',
	surveys: 'Surveys'
};

export const CSV_HEADERS = [
	FLAT_KEYS.line,
	FLAT_KEYS.jobNumber,
	FLAT_KEYS.jobStartDate,
	FLAT_KEYS.jobEndDate,
	FLAT_KEYS.jobUpdatedDate,
	FLAT_KEYS.customer,
	FLAT_KEYS.entity,
	FLAT_KEYS.projectManager,
	FLAT_KEYS.corrosionTechnician,
	FLAT_KEYS.aegionPm,
	FLAT_KEYS.aegionDps,
	FLAT_KEYS.surveys
];

export default { initialState };
